.MuiCardHeader-title {
  background-color: #198f77;
  color: #ffffff;
  margin: -1rem;
  padding: 1rem;
}

.MuiCardContent-root {
  font-size: 1rem;
  line-height: 1.5;
}

table {
  border-collapse: collapse;
}

table,
th,
td {
  font-size: 1rem;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  padding: 3px 5px;
}

th {
  font-weight: bold;
  background-color: #3a69ae;
  color: #ffffff;
}

tr:nth-child(odd) {
  background-color: #e2e7f0;
}

tr:nth-child(even) {
  background-color: #c3cbe2;
}

li:not(:first-child):not(.MuiBreadcrumbs-separator):not(.MuiBreadcrumbs-li) {
  margin: 10px 0;
}

h2 {
  background-color: #198f77;
  color: white;
  text-align: center;
  padding: 3pt;
}

h3 {
  background-color: #4b76ae;
  color: white;
  text-align: center;
  padding: 3pt;
}
