@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  font-size: 15px;
}

.MuiAccordionSummary-content {
  margin-top: 8px;
  margin-bottom: 8px;
}

.MuiCardContent-root {
  padding: 0;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
}
body {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
}
